export const environment = {
  production: false,
  environment: 'preprd',
  api_url: 'https://event-management-api.preprd-apps.enelx.com',
  utility_api_url: 'https://utility-portal-api.preprd-apps.enelx.com',
  exm_url: 'https://exception-management.preprd-apps.enelx.com',
  dmc_url: 'https://dmc-iot-dev.enel.com',
  der_url: 'https://cloud.beta.der.enelx.com',
  login_url: 'https://openam.preprd-apps.enelx.com/openam/XUI/',
  openAmCookieDomain: 'preprd-apps.enelx.com',
  globalNavUrl: 'https://global-nav-v2.preprd-svcs.enernoc.net/api/v2',
  aunt_url: 'https://exception-management.preprd-apps.enelx.com',
  reg_admin_url: 'https://registrations.preprd-apps.enelx.com/',
  e_cream_url: 'https://enernoc--test2.sandbox.lightning.force.com/',
  cdn: 'https://cdn.preprd-apps.enelx.com',
  defaults: {
    brand: 'enelx',
    locale: 'en-us',
    cookie_domain: '.preprd-apps.enelx.com'
  }

};
